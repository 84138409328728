var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"900px","persistent":"","scrollable":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Nueva Compañia Administrador Charlas Técnicas ")])]),_c('v-card-text',{staticClass:"mt-3"},[_c('validation-observer',{ref:"form"},[_c('v-container',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"vid":"companyIdentification","name":_vm.rutLabel,"rules":"required|nit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-1",attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":_vm.rutLabel +  ' (Sin código de verificación)',"required":""},model:{value:(_vm.model.companyIdentification),callback:function ($$v) {_vm.$set(_vm.model, "companyIdentification", $$v)},expression:"model.companyIdentification"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"vid":"companyBusinessName","name":"Razón Social","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-1",attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Razón social","required":""},model:{value:(_vm.model.companyBusinessName),callback:function ($$v) {_vm.$set(_vm.model, "companyBusinessName", $$v)},expression:"model.companyBusinessName"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"vid":"companyName","name":"Nombre comercial","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-1",attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre comercial","required":""},model:{value:(_vm.model.companyName),callback:function ($$v) {_vm.$set(_vm.model, "companyName", $$v)},expression:"model.companyName"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"vid":"companyEmail","name":"Correo corporativo","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-1",attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo corporativo","required":""},model:{value:(_vm.model.companyEmail),callback:function ($$v) {_vm.$set(_vm.model, "companyEmail", $$v)},expression:"model.companyEmail"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"name":"Confirmar Email","vid":"email_confirmation","rules":"required|email|max:80|confirmed:companyEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-1",attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Confirmar Email","required":""},model:{value:(_vm.model.email_confirmation),callback:function ($$v) {_vm.$set(_vm.model, "email_confirmation", $$v)},expression:"model.email_confirmation"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"vid":"companyPhone","name":"Teléfono de la empresa","rules":{required: true,phone: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{staticClass:"flex-wrap px-1",attrs:{"label":"Teléfono de la empresa","error-messages":errors,"mode":"international","inputOptions":{showDialCode:false},"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.companyPhone),callback:function ($$v) {_vm.$set(_vm.model, "companyPhone", $$v)},expression:"model.companyPhone"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"name":"Extensión","vid":"companyPhoneExtension","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión","required":""},model:{value:(_vm.model.companyPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "companyPhoneExtension", $$v)},expression:"model.companyPhoneExtension"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"name":"Dirección","vid":"companyAddress","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-1",attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Dirección","required":""},model:{value:(_vm.model.companyAddress),callback:function ($$v) {_vm.$set(_vm.model, "companyAddress", $$v)},expression:"model.companyAddress"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"name":"Página Web","vid":"companyWebSite","rules":"max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-1",attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Página Web","required":""},model:{value:(_vm.model.companyWebSite),callback:function ($$v) {_vm.$set(_vm.model, "companyWebSite", $$v)},expression:"model.companyWebSite"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"vid":"companyCountryId","name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"px-1",attrs:{"error-messages":errors,"items":_vm.countries,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"País"},on:{"change":_vm.onChangeCountry},model:{value:(_vm.model.companyCountryId),callback:function ($$v) {_vm.$set(_vm.model, "companyCountryId", $$v)},expression:"model.companyCountryId"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"vid":"companyDepartmentId","name":"Departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"px-1",attrs:{"error-messages":errors,"items":_vm.departments,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"Departamento"},on:{"change":_vm.onChangeDepartment},model:{value:(_vm.model.companyDepartmentId),callback:function ($$v) {_vm.$set(_vm.model, "companyDepartmentId", $$v)},expression:"model.companyDepartmentId"}})]}}])})],1),_c('div',{staticClass:"col-6 custom-col"},[_c('validation-provider',{attrs:{"vid":"companyCityId","name":"Ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"px-1",attrs:{"error-messages":errors,"items":_vm.cities,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"Ciudad"},model:{value:(_vm.model.companyCityId),callback:function ($$v) {_vm.$set(_vm.model, "companyCityId", $$v)},expression:"model.companyCityId"}})]}}])})],1)])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" Aceptar ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }